import moment from 'dayjs';

//有效期时长，-1代表长期
const limitMap = {
  idCard: [5, 10, 20, -1],
  driverLicense: [6, 10, -1]
};

const isSameDate = (a, b) => {
  return (
    moment(a)
      .startOf('day')
      .valueOf() ===
    moment(b)
      .startOf('day')
      .valueOf()
  );
};

export const isValidEndDate = (start, end, limits = 'idCard', isOneDayValid = limits === 'idCard') => {
  let limitArray = [];
  if (typeof limits === 'string') {
    limitArray = limitMap[limits] || [];
  } else if (Array.isArray(limits)) {
    limitArray = [...limits];
  }
  if (!limitArray.length) return true;
  return limitArray.some(year => {
    let sMoment = moment(start);
    let endMoment = moment(end);
    if (year === -1) {
      //长期
      return endMoment.year() === 2199;
    }
    let y = sMoment.year();
    let m = sMoment.month();
    let d = sMoment.date();
    if (m == 1 && d == 29) {
      //时间是闰年
      return endMoment.year() === y + year;
    }
    // return sMoment.add(year, 'y').valueOf() === end;
    const isSameDateFlag = isSameDate(sMoment.add(year, 'y'), endMoment);
    if (isSameDateFlag || !isOneDayValid) return isSameDateFlag;
    // return isSameDate(moment(start).add(year, 'y'), moment(end).add(1, 'd'));
    const endPlusOneDay = moment(end).add(1, 'd');
    const startPlusAddYear = moment(start).add(year, 'y');
    return isSameDate(startPlusAddYear, endPlusOneDay) && moment(end).year() === startPlusAddYear.year();
  });
};

export const dateCompareMethod = ({ value, toCompare, flag = '=', d = 0, y = 0 }) => {
  const momentDate = moment(value)
    .add(y, 'y')
    .add(d, 'd')
    .startOf('day')
    .valueOf();
  const nowDate = moment(toCompare)
    .startOf('day')
    .valueOf();
  switch (flag) {
    case '=':
      return momentDate === nowDate;
    case '<':
      return momentDate < nowDate;
    case '>':
      return momentDate > nowDate;
    default:
      return false;
  }
};

//当前时间晚于结束时间或生效日期的开始时间-当前时间大于180天的：驾驶证日期不合规！
export const driverLicenseDateRangeValidate = (start, end, gap = 180) => {
  const now = Date.now();
  //结束时间早于当前时间
  const isEndBeforeNow = dateCompareMethod({ value: end, toCompare: now, flag: '<' });
  if (isEndBeforeNow) return false;
  //开始时间晚于当前时间+gap
  const isStartInValid = dateCompareMethod({ value: now, toCompare: start, d: gap, flag: '<' });
  return !isStartInValid;
};
